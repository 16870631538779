import { FilteredMeasureDto } from "api-shared";

/**
 * Filter measures by their title. Only measures where all words of the search key are contained will be returned.
 * Search key will be split by space.
 *
 * @param {string} searchKey
 * @param {MeasureDto[]} measures
 * @returns {MeasureDto[]}
 */
export const quickSearch = (searchKey: string, measures: FilteredMeasureDto[]): FilteredMeasureDto[] => {
    if (searchKey.trim().length > 0) {
        const searchTerms = searchKey.split(" ").map((t) => t.toLowerCase());
        return measures.filter(({ title, clientIid }) =>
            searchTerms.every((t) => title.toLowerCase().indexOf(t) > -1 || clientIid.toString() === t),
        );
    } else {
        return measures;
    }
};
