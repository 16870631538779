import { styled } from "@mui/material";

import { FilteredMeasureDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import { useToggleFavoriteMeasure } from "../../domain/measure/favorite";
import FavoriteIcon from "../icons/FavoriteIcon";

const Root = styled("div")(({ theme }) => ({
    // reset default padding of table cell with values matching with FavoriteIcon's IconButton small variant
    margin: theme.spacing(-1, -0.625),
}));

function TableMeasureFavoriteCell({ row, value }: CellProps<FilteredMeasureDto>) {
    const { t: translate } = useTranslation();
    const toggleFavoriteMeasure = useToggleFavoriteMeasure(row.original.id, row.original.isFavorite, row.original.currentGateTaskConfigId);
    const isFavorite = row.original.isFavorite;
    return (
        <Root>
            <FavoriteIcon iconSize="small" value={isFavorite} updateValue={toggleFavoriteMeasure} translate={translate} />
        </Root>
    );
}

export default TableMeasureFavoriteCell;
