import { Box, Typography, styled } from "@mui/material";
import { EffectCategoryAttributeDto, FilteredEffectCategoryDto } from "api-shared";
import { TFunction } from "i18next";
import { isNumber, sortBy } from "lodash";
import Daterange from "../../components/Daterange";
import { useCurrencies } from "../../domain/currencies";
import useCurrency from "../../hooks/useCurrency";
import useFieldData from "../../hooks/useFieldData";
import { TranslatedOption } from "../../lib/field-options";
import { translationKeys } from "../../translations/main-translations";
import PreviewPartialEffect from "./PreviewPartialEffect";

interface IPreviewEffectCategoryProps {
    effectCategory: FilteredEffectCategoryDto;
    effectCategoryAttributes: EffectCategoryAttributeDto[];
    translate: TFunction;
    isFeedPreview?: boolean;
}

const ScopedTypography = styled(Typography, { shouldForwardProp: (name) => name !== "isInScope" })<{ disabled?: boolean }>(
    ({ theme, disabled }) => ({
        ...(disabled && { color: theme.palette.action.disabled }),
    }),
);

function resolveFieldValue(
    effectCategoryAttributes: EffectCategoryAttributeDto[],
    field: EffectCategoryAttributeDto,
    effectCategory: FilteredEffectCategoryDto,
    fieldData: TranslatedOption[][],
) {
    const index = effectCategoryAttributes.findIndex((f) => f === field);
    const value = effectCategory.categoryFields[field.title];
    const options = fieldData[index];
    return options.find(({ id }) => id === value)?.name ?? null;
}

const PreviewEffectCategory = ({ translate, effectCategory, effectCategoryAttributes, isFeedPreview }: IPreviewEffectCategoryProps) => {
    const curencies = useCurrencies();
    const { formatCurrency } = useCurrency({ currency: curencies.find((c) => c.id == effectCategory.currencyId) });

    const orderedEffectCategoryFields = sortBy(effectCategoryAttributes, (x) => x.order);
    const fieldData = useFieldData(orderedEffectCategoryFields, { fullTreePathValues: true });

    const { inputEffectNoScope, inputEffect, startDate, endDate } = effectCategory.calculationFields;

    // isInScope may be undefined, when scope is not activated. Then, the EC should be shown as if it were in scope.
    // Since the feeds view does not offer scope settings, the EC should also always be shown as in-scope if the preview is originating from the feed.
    const isInScope = effectCategory.isInScope !== false || isFeedPreview;
    const isIncomplete = [startDate, endDate, inputEffectNoScope].some((value) => value === null);

    const [titleField, ...otherFields] = orderedEffectCategoryFields;

    const label = resolveFieldValue(orderedEffectCategoryFields, titleField, effectCategory, fieldData);
    const effectType = translate(`effect_type_${effectCategory.effectType}`);
    const otherValues = otherFields.map((field) => resolveFieldValue(orderedEffectCategoryFields, field, effectCategory, fieldData));

    return (
        <>
            {isIncomplete ? (
                <ScopedTypography color="textPrimary" gridColumn="1 / 3" disabled={!isInScope}>
                    {`${label ?? ""} – `}
                    <Box component="span" color={isInScope ? "error.main" : undefined}>
                        {translate(translationKeys.VDLANG_MEASURE_PREVIEW_NOT_FILLED_YET)}
                    </Box>
                </ScopedTypography>
            ) : (
                <>
                    <ScopedTypography color="textPrimary" disabled={!isInScope}>
                        {label}
                    </ScopedTypography>
                    <ScopedTypography variant="subtitle2" color="textSecondary" align="right" disabled={!isInScope}>
                        <PreviewPartialEffect
                            partial={isNumber(inputEffect) ? inputEffect : undefined}
                            total={isNumber(inputEffectNoScope) ? inputEffectNoScope : null}
                            formatCurrency={formatCurrency}
                            disabled={!isInScope}
                        />
                    </ScopedTypography>
                </>
            )}
            <ScopedTypography as="ul" pb={2} pl={3} color="textSecondary" disabled={!isInScope}>
                <li>{effectType}</li>
                {otherValues.map((ov) => (
                    <li key={ov}>{ov}</li>
                ))}
            </ScopedTypography>
            <ScopedTypography pb={2} color="textSecondary" align="right" disabled={!isInScope}>
                <Daterange startDate={startDate} endDate={endDate} monthYearFormat />
            </ScopedTypography>
        </>
    );
};

export default PreviewEffectCategory;
