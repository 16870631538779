import { styled } from "@mui/material";
import { CurrencyFormatter } from "../../hooks/useCurrency";

function renderPlaceholder(formatCurrency: CurrencyFormatter): string {
    const formatted0 = formatCurrency(0);
    // replace everything between two numbers with a "--", this way, locale-dependant positioning of the currency code is preserved
    return formatted0?.replace(/\d.*\d/g, "--") ?? "--";
}

interface IPreviewPartialEffectProps {
    partial?: number; // allow field values being passed in easily, component does type checking itself
    total: number | null;
    formatCurrency: CurrencyFormatter;
    disabled?: boolean;
}

const Partial = styled("span", { shouldForwardProp: (name) => name !== "value" && name !== "disabled" })<{
    value: number;
    disabled?: boolean;
}>(({ theme, value, disabled }) => ({
    ...(!disabled && value > 0 && { color: theme.palette.success.dark }),
    ...(!disabled && value < 0 && { color: theme.palette.error.dark }),
}));

const PreviewPartialEffect = ({ disabled, partial: numerator, total: denominator, formatCurrency }: IPreviewPartialEffectProps) => {
    if (denominator === null) {
        // no effect yet => display only "-- EUR"
        return <>{renderPlaceholder(formatCurrency)}</>;
    }

    const resolvedNumerator = numerator ?? denominator;

    const formattedNumerator = (
        <Partial disabled={disabled} value={resolvedNumerator}>
            {formatCurrency(resolvedNumerator)}
        </Partial>
    );

    if (denominator === resolvedNumerator) {
        // do not repeat same values, only show the primary (colorized) one
        return formattedNumerator;
    }

    const formattedDenominator = formatCurrency(denominator) ?? renderPlaceholder(formatCurrency);

    return (
        <>
            {formattedNumerator}
            {` / ${formattedDenominator}`}
        </>
    );
};

export default PreviewPartialEffect;
