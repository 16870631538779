import PreviewIcon from "@mui/icons-material/PreviewRounded";
import { darken, IconButton, styled } from "@mui/material";
import { FilteredMeasureDto } from "api-shared";
import useDialog from "../../hooks/useDialog";
import { trackEvent } from "../../infrastructure/tracking";
import ProcessPreviewDialog from "./ProcessPreviewDialog";

const PreviewButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(0.75),
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
        // usually alpha function is taken, but it does not work for pure white
        backgroundColor: darken(theme.palette.background.paper, theme.palette.action.hoverOpacity),
    },
}));

interface IProcessPreviewButtonProps {
    className?: string;
    measure: FilteredMeasureDto;
}

const ProcessPreviewButton = ({ className, measure }: IProcessPreviewButtonProps) => {
    const { isOpen, openDialog, closeDialog } = useDialog();

    function handleOpenDialog() {
        trackEvent({ category: "Measure", action: "Measure Preview Opened" });

        openDialog();
    }

    return (
        <>
            <ProcessPreviewDialog measure={measure} open={isOpen} onClose={closeDialog} />
            <PreviewButton className={className} onClick={handleOpenDialog}>
                <PreviewIcon fontSize="small" />
            </PreviewButton>
        </>
    );
};

export default ProcessPreviewButton;
