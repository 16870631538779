import {
    EffectField,
    FieldDefinition,
    FieldDefinitionsDto,
    FieldTypes,
    FilteredMeasureDto,
    GlobalCalculationIdentifier,
    MeasureFieldNames,
    UserDto,
    effortConverter,
    mergeCamelized,
} from "api-shared";
import { memoize } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import { useMeasureAttributes } from "../../domain/endpoint";
import { findField, isTranslateableConstant, removeDependsFromField } from "../../lib/fields";
import { replaceImage } from "../../lib/history";
import { replaceMentionUsers } from "../../lib/mention";
import OptionsProvider from "../../view/OptionsProvider";
import IdResolver from "../IdResolver";
import TableConstantsCell from "../table/TableConstantsCell";
import TableCurrencyCell from "../table/TableCurrencyCell";
import TableDateCell, { DateFormat } from "../table/TableDateCell";
import TableFloatCell from "../table/TableFloatCell";
import TableGroupListCell from "../table/TableGroupListCell";
import TableLinkCell from "../table/TableLinkCell";
import TableMeasureFavoriteCell from "../table/TableMeasureFavoriteCell";
import TableMeasureIdCell from "../table/TableMeasureIdCell";
import TableMeasureTitleCell from "../table/TableMeasureTitleCell";
import TableProcessPulseCell from "../table/TableProcessPulseCell";
import TableTextCell from "../table/TableTextCell";
import TableUserCell from "../table/TableUserCell";

interface IUseMeasuresTableCellRenderersProps {
    fieldDefinitions: FieldDefinitionsDto;
    users: UserDto[];
}

const useMeasuresTableCellRenderers = ({ fieldDefinitions, users }: IUseMeasuresTableCellRenderersProps) => {
    const measureAttributes = useMeasureAttributes();

    const { t: translate } = useTranslation();

    // use one large memo here to avoid having dedicated useCallback/useMemos for each renderer
    // The data dependencies rarely change
    // Inline-function needed, so that dependencies can be checked by linter -> disable cognitive complexity check here
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return useMemo(() => {
        // Renderers that do not depend on extra column/field information
        // Data formatting can be done without additional field information
        const renderMeasureTitleCell = (props: CellProps<FilteredMeasureDto>) => <TableMeasureTitleCell {...props} />;
        const renderLinkedActivities = (props: CellProps<FilteredMeasureDto>) => {
            const measure = props.row.original;
            return <TableLinkCell link={`/measure/${measure.id}/activities`} {...props} />;
        };

        const renderLinkedDocuments = (props: CellProps<FilteredMeasureDto>) => {
            const measure = props.row.original;
            return <TableLinkCell link={`/measure/${measure.id}/documents`} {...props} />;
        };

        const renderUser = (props: CellProps<FilteredMeasureDto>) => <TableUserCell users={users} {...props} />;

        const renderMarkdown = ({ value }: CellProps<FilteredMeasureDto>) => (
            <TableTextCell value={replaceImage(replaceMentionUsers(users, value, translate))} />
        );

        const renderEffort = ({ value }: CellProps<FilteredMeasureDto>) => <TableTextCell>{effortConverter(value)}</TableTextCell>;

        // Renderers that require additional field information, so they cannot be re-used across multiple columns
        // react-table requires stable references to be performant, so these factories are wrapped with memoize() for simple caching
        // across multiple keys.
        const makeDateRenderer = memoize((field: FieldDefinition) => (props: CellProps<FilteredMeasureDto>) => {
            // We want to format the date as month and year if the field is a date field indicating a range of effectiveness
            // The regular mm.dd.yyyy format will still be used when exporting to CSV
            const effectDateFieldNames = [
                EffectField.StartDate,
                EffectField.EndDate,
                ...Object.values(GlobalCalculationIdentifier).map((calculation) => mergeCamelized(calculation, EffectField.StartDate)),
                ...Object.values(GlobalCalculationIdentifier).map((calculation) => mergeCamelized(calculation, EffectField.EndDate)),
            ];
            return (
                <TableDateCell
                    noTimezone={field.isTimezoneAware === false}
                    format={effectDateFieldNames.includes(field.name) ? DateFormat.MonthYear : undefined}
                    {...props}
                />
            );
        });

        const makeIdRenderer = memoize((column: string) => {
            const attribute = findField(measureAttributes, fieldDefinitions, column);
            if (attribute == null) {
                return "";
            }

            const field = removeDependsFromField(attribute);

            return ({ value }: CellProps<FilteredMeasureDto>) =>
                value == null ? null : (
                    <OptionsProvider field={field} value={value}>
                        <IdResolver values={value} />
                    </OptionsProvider>
                );
        });

        const makeDoubleRenderer = memoize((column: string) => (props: CellProps<FilteredMeasureDto>) => {
            const field = findField(measureAttributes, fieldDefinitions, column);
            // Efficiency fields don't have an attribute, for them the fallback value will be used
            const digits = field?.options?.fractionDigits ?? 1;
            return <TableFloatCell minimumFractionDigits={digits} maximumFractionDigits={digits} {...props} />;
        });

        return (column: string) => {
            const field = fieldDefinitions[column];
            if (!field) {
                return "";
            }

            if (column === MeasureFieldNames.ClientIid) {
                return TableMeasureIdCell;
            }
            if (field.name === MeasureFieldNames.Title) {
                return renderMeasureTitleCell;
            }
            if (
                field.name === MeasureFieldNames.OpenSubtasks ||
                field.name === MeasureFieldNames.Subtasks ||
                field.name === MeasureFieldNames.OverdueSubtasks ||
                field.name === MeasureFieldNames.UnassignedSubtasks
            ) {
                return renderLinkedActivities;
            }
            if (field.name === MeasureFieldNames.Documents) {
                return renderLinkedDocuments;
            }
            if (field.name === MeasureFieldNames.Favorite) {
                return TableMeasureFavoriteCell;
            }
            if (isTranslateableConstant(column)) {
                return TableConstantsCell;
            }
            if (field.name === MeasureFieldNames.GroupsWithAccess) {
                return TableGroupListCell;
            }
            switch (field.type) {
                case FieldTypes.Currency:
                    return TableCurrencyCell;
                case FieldTypes.Date:
                    return makeDateRenderer(field);
                case FieldTypes.Single:
                case FieldTypes.Set:
                case FieldTypes.Users:
                    return makeIdRenderer(column);
                case FieldTypes.User:
                    return renderUser;
                case FieldTypes.Text:
                    return renderMarkdown;
                case FieldTypes.TimeEstimate:
                    return renderEffort;
                case FieldTypes.Pulse:
                    return TableProcessPulseCell;
                case FieldTypes.Double:
                    return makeDoubleRenderer(column);
                default:
                    return TableTextCell;
            }
        };
    }, [fieldDefinitions, translate, measureAttributes, users]);
};

export default useMeasuresTableCellRenderers;
