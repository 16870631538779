import { styled, tableRowClasses } from "@mui/material";
import { FilteredMeasureDto } from "api-shared";
import ProcessPreviewButton from "../../view/measures/ProcessPreviewButton";
import { CellProps } from "react-table";
import TableLinkCell from "./TableLinkCell";

const RootContainer = styled("div")(({ theme }) => ({
    position: "relative", // anchor for absolutely positioned preview button
    // revert padding of td element, so that button will not be clipped
    margin: theme.spacing(-1, -2),
    padding: theme.spacing(1, 2),
}));

const PreviewButton = styled(ProcessPreviewButton)(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(2),
    // vertical center
    top: "50%",
    transform: "translateY(-50%)",
    ["@media (hover: hover)"]: {
        opacity: 0,
        [`.${tableRowClasses.hover}:hover &`]: {
            opacity: 1,
        },
    },
}));

const TableMeasureTitleCell = (props: CellProps<FilteredMeasureDto>) => {
    const measure = props.row.original;
    return (
        <RootContainer>
            <TableLinkCell link={`/measure/${measure.id}`} {...props}>
                <PreviewButton measure={measure} />
            </TableLinkCell>
        </RootContainer>
    );
};

export default TableMeasureTitleCell;
