import { styled, tableCellClasses } from "@mui/material";
import {
    FieldDefinitionsDto,
    FieldTypes,
    FilteredMeasureDto,
    FilteredMeasuresDto,
    MeasureFieldNames,
    MeasureStatus,
    Sort,
} from "api-shared";
import { TFunction } from "i18next";
import { memoize } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Accessor, Column, SortingRule, type CellProps } from "react-table";
import { useDefaultCurrency } from "../../domain/currencies";
import { useMeasureFieldDefinitionsQuery } from "../../domain/filters";
import { useAllUsers } from "../../domain/users";
import { useGetDynamicFieldLabel } from "../../hooks/useGetDynamicFieldLabel";
import { quickSearch } from "../../lib/quick-search";
import { translationKeys } from "../../translations/main-translations";
import BaseTable, { IBaseTableProps } from "../table/BaseTable";
import TableHeaderCell from "../table/TableHeaderCell";
import TableTextCell from "../table/TableTextCell";
import useMeasuresTableCellRenderers from "./useMeasuresTableCellRenderers";

const processPulseCellClass = "VdMeasuresTable-processPulseCell";

const MeasuresBaseTable = styled(BaseTable)({
    [`& .${processPulseCellClass}.${tableCellClasses.body}`]: {
        // remove default padding from pulse cell, so that it can use all of the available space for drawing
        padding: 0,
        overflow: "visible",
    },
}) as typeof BaseTable;

const isDescendingOrder = (val: Sort) => val === "DESC";

const getInfoTooltip = (columnId: string, translate: TFunction) =>
    columnId === MeasureFieldNames.ProcessPulse ? translate(`${columnId}Hint`) : undefined;

const getWidthForColumn = (columnId: string, defaultColumnWidths?: Record<string, number>) => {
    let defaultWidth = 150;
    switch (columnId) {
        case MeasureFieldNames.ClientIid:
            defaultWidth = 104;
            break;
        case MeasureFieldNames.ProcessPulse:
            defaultWidth = 192;
            break;
        case MeasureFieldNames.Title:
        case "description":
            defaultWidth = 320;
            break;
    }
    return defaultColumnWidths?.[columnId] ?? defaultWidth;
};

// key type can be any string because of custom fields, MeasureFieldNames is not enough here
const RESIZING_MIN_WIDTHS: Partial<Record<string, number>> = {
    [MeasureFieldNames.ProcessPulse]: 192,
    [MeasureFieldNames.ClientIid]: 104,
};

export interface IMeasuresTableProps extends Omit<IBaseTableProps<FilteredMeasureDto>, "columns" | "data" | "noDataText" | "translate"> {
    orderBy: string;
    data?: FilteredMeasuresDto;
    sort: Sort;
    pageSize: number;
    onPageChanged: (newPageSize: number, newPage: number) => void;
    onSortChanged: (newOrderBy: string, newSort: Sort) => void;
    defaultColumnWidths?: Record<string, number>;
    columns: string[];
    searchKey?: string;
    disableFooter?: boolean;
    enableQueries?: boolean;
}

// customize the ids, the the react "key" props are stable across sorts so memoization should work even when sorting (and row indicess) change
const getRowIdForMeasure = (row: FilteredMeasureDto) => {
    return String(row.clientIid);
};

const EMPTY_DATA = { measures: [], sums: {}, matchingItems: 0 };

const dummyFieldDefinitions = {} as FieldDefinitionsDto;

const MeasuresTable = ({
    orderBy,
    sort,
    pageSize,
    pageIndex,
    onPageChanged,
    onSortChanged,
    defaultColumnWidths,
    columns,
    searchKey,
    disableFooter,
    data = EMPTY_DATA,
    isFetching,
    enableQueries = true,
    ...other
}: IMeasuresTableProps) => {
    // remember the value of the first provided column widths
    const [initialColumnsWidths] = useState(defaultColumnWidths);

    const { t: translate } = useTranslation();
    const clientCurrency = useDefaultCurrency();

    const users = useAllUsers();
    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery(enableQueries);
    const fieldDefinitions = fieldDefinitionsQuery.data ?? dummyFieldDefinitions;
    const { getDynamicColumnLabel } = useGetDynamicFieldLabel();

    const combinedSort = useMemo(() => [{ id: orderBy, desc: isDescendingOrder(sort) }], [sort, orderBy]);

    const resolveCellRenderer = useMeasuresTableCellRenderers({
        fieldDefinitions,
        users,
    });

    const updatePagination = useCallback(
        (newPage: number, newPageSize = pageSize) => {
            if (newPage !== pageIndex || newPageSize !== pageSize) {
                onPageChanged(newPageSize, newPage);
            }
        },
        [onPageChanged, pageIndex, pageSize],
    );

    const changeSort = useCallback(
        (newSorted: SortingRule<FilteredMeasureDto>[]) => {
            const newOrderBy = newSorted[0].id;
            const newSort = newSorted[0].desc ? Sort.DESCENDING : Sort.ASCENDING;
            if (newOrderBy !== orderBy || newSort !== sort) {
                onSortChanged(newOrderBy, newSort);
            }
        },
        [onSortChanged, orderBy, sort],
    );

    const getSumRenderer = useMemo(
        () =>
            memoize((column: string) => {
                const CellRenderer = resolveCellRenderer(column);

                if (CellRenderer === "") {
                    // CellRenderer might be an empty string to show an empty cell, but that is not a valid JSX constructor
                    return CellRenderer;
                }

                return (props: CellProps<FilteredMeasureDto>) => (
                    <CellRenderer {...props} variant="subtitle2" value={data.sums?.[column] ?? 0} />
                );
            }),
        [data.sums, resolveCellRenderer],
    );

    const renderMeasureCount = useCallback(
        () =>
            data.matchingItems != null ? (
                <TableTextCell variant="subtitle2">{`${data.matchingItems} ${translate("processes")}`}</TableTextCell>
            ) : null,
        [data.matchingItems, translate],
    );

    const getFooterRenderer = useCallback(
        (column: string, index: number) => {
            if (index === 0) {
                // special case for first column, display amount of all measures
                return renderMeasureCount;
            }
            const field = fieldDefinitions[column];

            switch (field?.type) {
                case FieldTypes.Currency:
                case FieldTypes.Double:
                    return getSumRenderer(column);
                default:
                    return "";
            }
        },
        [fieldDefinitions, renderMeasureCount, getSumRenderer],
    );

    const getColumnAccessor = useMemo(() => {
        return memoize((columnName: string): Accessor<FilteredMeasureDto> => {
            const field = fieldDefinitions[columnName];
            if (field?.attributeName != null) {
                return (row: FilteredMeasureDto) => row.fields[columnName];
            }
            return (row: FilteredMeasureDto) => row.calculatedFields[columnName] ?? row[columnName as keyof typeof row] ?? undefined;
        });
    }, [fieldDefinitions]);

    const tableColumns = useMemo(
        () =>
            columns.map(
                (columnName, index) =>
                    ({
                        Header: TableHeaderCell,
                        id: columnName,
                        label: getDynamicColumnLabel(columnName, clientCurrency.isoCode),
                        headerTooltip: getInfoTooltip(columnName, translate),
                        translate,
                        width: getWidthForColumn(columnName, initialColumnsWidths),
                        minWidth: RESIZING_MIN_WIDTHS[columnName] ?? 72,
                        // force accessor to be string, because columnName might be an int,
                        // which confuses the table
                        accessor: getColumnAccessor(columnName),
                        disableSortBy: !fieldDefinitions[columnName]?.isSortable,
                        sortDescFirst: true,
                        Cell: resolveCellRenderer(columnName),
                        Footer: disableFooter ? undefined : getFooterRenderer(columnName, index) || "",
                        className: columnName === MeasureFieldNames.ProcessPulse ? processPulseCellClass : undefined,
                    }) as Column<FilteredMeasureDto>,
            ),
        [
            columns,
            getDynamicColumnLabel,
            clientCurrency.isoCode,
            translate,
            initialColumnsWidths,
            getColumnAccessor,
            fieldDefinitions,
            resolveCellRenderer,
            disableFooter,
            getFooterRenderer,
        ],
    );

    const measuresData = useMemo(
        () =>
            searchKey !== undefined && searchKey.length > 0 && data.measures != null
                ? quickSearch(searchKey, data.measures)
                : data.measures,
        [data.measures, searchKey],
    );

    const isProcessDiscarded = useCallback((row: FilteredMeasureDto): boolean => row.status === MeasureStatus.STATUS_DISCARDED, []);

    const pageCount = data.matchingItems == null ? 0 : Math.ceil(data.matchingItems / pageSize);

    return (
        <MeasuresBaseTable<FilteredMeasureDto>
            data={measuresData}
            columns={tableColumns}
            /* pagination */
            manualPagination
            numOfItems={data.matchingItems}
            defaultPageSize={pageSize}
            pageCount={pageCount}
            onPaginationChanged={updatePagination}
            /* sorting */
            manualSortBy
            defaultSortBy={combinedSort}
            onSortByChanged={changeSort}
            itemName="processes"
            noDataText={translate(translationKeys.VDLANG_NO_PROCESSES)}
            translate={translate}
            rowHover
            isRowSelected={isProcessDiscarded}
            pageIndex={pageIndex}
            getRowId={getRowIdForMeasure}
            isFetching={!fieldDefinitionsQuery.isSuccess || isFetching}
            {...other}
        />
    );
};

export default React.memo(MeasuresTable);
