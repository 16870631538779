import { styled } from "@mui/material";
import { FilteredMeasureDto } from "api-shared";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BaseDialog, { IBaseDialogProps, baseDialogClasses } from "../../components/dialogues/BaseDialog";
import DialogBackButton from "../../components/dialogues/DialogBackButton";
import DialogButton from "../../components/dialogues/DialogButton";
import { useMeasureConfig } from "../../domain/measure-config";
import { trackEvent } from "../../infrastructure/tracking";
import { translationKeys } from "../../translations/main-translations";
import { Partialize } from "../../types/helpers";
import ProcessPreviewDialogContent from "./ProcessPreviewDialogContent";

const WideBaseDialog = styled(BaseDialog)(({ theme }) => ({
    [`& .${baseDialogClasses.content}`]: {
        padding: theme.spacing(3, 3, 4),
    },
}));

interface IProcessPreviewDialogProps extends Partialize<IBaseDialogProps, "title"> {
    measure: FilteredMeasureDto;
    isFeedPreview?: boolean;
}

const ProcessPreviewDialog = ({ measure, onClose, isFeedPreview, ...baseDialogProps }: IProcessPreviewDialogProps) => {
    const { t: translate } = useTranslation();

    const measureConfig = useMeasureConfig(measure);
    const navigate = useNavigate();

    const goToMeasure = useCallback(() => {
        trackEvent({ category: "Feed", action: "Feed Process Opened" });
        navigate(`/measure/${measure.id}`);
    }, [measure.id, navigate]);

    const processName = measureConfig != null ? translate(measureConfig.name) : "";

    const actions = [
        <DialogBackButton name={translate(translationKeys.VDLANG_CLOSE)} key="close" onClose={onClose} />,
        <DialogButton
            variant="contained"
            key="navigate"
            name={translate(translationKeys.VDLANG_GOTO_MEASURE, { processName })}
            onClose={onClose}
            onClick={goToMeasure}
        />,
    ];

    if (measureConfig == null) {
        return null;
    }

    return (
        <WideBaseDialog onClose={onClose} actions={actions} {...baseDialogProps}>
            <ProcessPreviewDialogContent
                measure={measure}
                translate={translate}
                measureConfig={measureConfig}
                isFeedPreview={isFeedPreview}
            />
        </WideBaseDialog>
    );
};

export default ProcessPreviewDialog;
