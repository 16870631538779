import { Popper, styled, SvgIcon, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { TooltipProps } from "recharts";
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import useTimezone, { DateTimeFormatter } from "../hooks/useTimezone";

const Container = styled("div")(({ theme }) => ({
    backgroundColor: blueGrey[900],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
}));

const SVGActivityIcon = styled(SvgIcon)(({ theme }) => ({
    height: "8px",
    color: theme.palette.primary.main,
}));
const SVGProcessIcon = styled(SvgIcon)(({ theme }) => ({
    height: "8px",
    color: theme.palette.magicPurple.main,
}));

const TooltipPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.tooltip,
}));

interface IInvisibleReferenceElementProps {
    height: number;
}
const InvisibleReferenceElement = styled("div")(({ height }: IInvisibleReferenceElementProps) => ({
    visibility: "hidden",
    width: 1, // 0 breaks popper positioning
    // Make the reference element span the full chart height, so that the popper will place the tooltip fully above or fully below the chart
    height,
    transform: `translateY(${-height / 2}px)`,
}));

const weekSpan = 7;
const weekOffset = 1;
const weekTimeFrameCalc = (weekID: number, formatDate: DateTimeFormatter) => {
    const start = formatDate(moment().subtract((weekID + 1) * weekSpan - weekOffset, "days"));
    const end = formatDate(moment().subtract(weekID * weekSpan, "days"));
    return `${start}  -  ${end}`;
};

interface ITooltipProcessPulseProps extends TooltipProps<ValueType, NameType> {
    chartHeight: number;
}

const TooltipProcessPulse = ({ chartHeight, active, payload }: ITooltipProcessPulseProps) => {
    const { t: translate } = useTranslation();
    const { formatDate } = useTimezone();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    if (!active || payload == null || payload.length === 0) {
        return null;
    }

    const dateRange = weekTimeFrameCalc(+payload?.[0].payload.week, formatDate);
    return (
        <InvisibleReferenceElement height={chartHeight} ref={setAnchorEl}>
            <TooltipPopper
                placement="top"
                modifiers={[
                    {
                        name: "flip",
                        enabled: true,
                    },
                    {
                        name: "preventOverflow",
                        enabled: true,
                        options: {
                            altAxis: true,
                        },
                    },
                ]}
                popperOptions={{
                    strategy: "fixed",
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
            >
                <Container>
                    <Typography variant="caption" fontWeight="medium">
                        {dateRange}
                    </Typography>
                    <br />
                    <Typography variant="caption">
                        <SVGProcessIcon fontSize="small" viewBox="0 0 10 10">
                            <circle cx="5" cy="5" r="5"></circle>
                        </SVGProcessIcon>
                        <span>
                            {payload?.[0].payload.processChanges} {translate("processPulseProcessChange")}
                        </span>
                    </Typography>
                    <br />
                    <Typography variant="caption">
                        <SVGActivityIcon color="primary" fontSize="small" viewBox="0 0 10 10">
                            <circle cx="5" cy="5" r="5"></circle>
                        </SVGActivityIcon>
                        <span>
                            {payload?.[0].payload.activityChanges} {translate("processPulseActivityChange")}
                        </span>
                    </Typography>
                </Container>
            </TooltipPopper>
        </InvisibleReferenceElement>
    );
};

export default TooltipProcessPulse;
