import { SvgIcon, SvgIconProps, styled } from "@mui/material";
import React from "react";
import withTooltip from "../../hocs/withTooltip";

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(8),
}));

const UnseenChangesIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <StyledSvgIcon color="primary" viewBox="0 0 10 10" {...props} ref={ref}>
        <circle cx="5" cy="5" r="5"></circle>
    </StyledSvgIcon>
));

export default withTooltip(UnseenChangesIcon);
