import { Stack, Typography } from "@mui/material";

import { isArray } from "lodash";
import { CellProps } from "react-table";
import { useGroups } from "../../domain/group";

import { mergeCamelized } from "api-shared";
import { useLanguage } from "../../hooks/useLanguage";
import { getFullGroupPath } from "../../lib/groups";
import { renderPath } from "../../lib/tree";
import Tooltip from "../Tooltip";

const TableGroupListCell = <D extends object = Record<string, unknown>>({ value }: CellProps<D>) => {
    const groups = useGroups();
    const language = useLanguage();

    if (value == null || groups.data === undefined) {
        return null;
    }

    const groupIds = isArray(value) ? value : [value];

    const fullGroupPath = (id: number): string => {
        const group = groups.data.find((g) => g.id === id);
        if (group === undefined) {
            return id.toString();
        }

        const path = getFullGroupPath(groups.data, group);
        return renderPath(path, mergeCamelized("name", language));
    };

    const groupList = groupIds.map((groupId) => fullGroupPath(groupId)).join(", ");

    return (
        <Stack direction="row" spacing={0.875} alignItems="center" maxWidth="100%" useFlexGap>
            <Tooltip title={groupList} onlyOverflowing>
                <Typography noWrap>{groupList}</Typography>
            </Tooltip>
        </Stack>
    );
};

export default TableGroupListCell;
