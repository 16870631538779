import { Skeleton, styled, Typography, useTheme } from "@mui/material";
import { FilteredMeasureDto } from "api-shared";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { CellProps } from "react-table";
import { CartesianGrid, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from "recharts";
import type { ScatterPointItem } from "recharts/types/cartesian/Scatter";
import { useProcessPulse } from "../../domain/process-pulse";
import { trackEvent } from "../../infrastructure/tracking";
import { stripPxSuffix } from "../../styles/utils";
import TooltipProcessPulse from "../TooltipProcessPulse";

/**
 * This can be used to test the charting more thoroughly with random/linear distributed data
 *
 * @param {("random" | "linear")} [mode="random"]
 * @returns
 */
// const getMockData = (numberOfWeeks: number, mode: "random" | "linear" = "random") => {
//     const mockDomain = [...times(150, () => 0), ...times(50)];
//     return times(numberOfWeeks, (i) => ({
//         week: i,
//         processChanges: mode === "random" ? sample(mockDomain)! : 1 + i * 4,
//         activityChanges: mode === "random" ? sample(mockDomain)! : 1 + i * 4,
//     }));
// };

// Each data series will get its own ZAxis with different accessors, so the pulseData can be directly used as scatter data without mapping
enum DataSeries {
    ProcessChanges,
    ActivityChanges,
}

const Root = styled("div")(({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
}));

const ErrorRoot = styled(Root)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

// This is how d3-scale objects need to be structured
function makeActivityScale() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let _domain: number[] = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let _range: number[] = [];

    const scaleFunction = (value: number) => {
        const [min, max] = _range;

        // clamp to range when outside of range
        if (value <= min) {
            return min;
        } else if (value > max) {
            return max;
        }
        // apply scaling function when value is inside range
        return min + max * Math.sqrt(value / max);
    };

    scaleFunction.domain = (newDomain: number[]) => {
        if (newDomain != null) {
            _domain = newDomain.slice(); // clone array
            return scaleFunction;
        }
        return _domain;
    };

    scaleFunction.range = (newRange: number[]) => {
        if (newRange != null) {
            _range = newRange.slice(); // clone array
            return scaleFunction;
        }
        return _range;
    };
    return scaleFunction;
}

interface IProcessPulseProps {
    width: number;
    processId: number;
    height?: number;
}

interface ITableProcessPulseCellProps extends CellProps<FilteredMeasureDto> {
    height?: number;
}

interface IPulseBubbleProps extends ScatterPointItem {
    fill?: string;
    fillOpacity?: string | number;
}

const NO_MARGINS = {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
};

// Will be used to provide always 0 as y value, without needing to modify the data
const returnZero = () => 0;

const PulseSkeleton = styled(Skeleton)(({ theme }) => ({
    margin: theme.spacing(0, 0.5),
}));

/**
 * Render a single ProcessBubble. size is interpreted as bubble height, location of the center point is given by (cx, cy)
 *
 * A pre-defined shape is scaled and moved with SVG's transform to the target location/size
 *
 * @param {ScatterPointItem} { fill, fillOpacity, cx, cy, size }
 * @returns
 */
const PulseBubble = ({ fill, fillOpacity, cx, cy, size }: IPulseBubbleProps) => {
    // The predefined path describes a shape with dimensions 78*39
    const scaleFactor = (size ?? 1) / 39;
    return (
        <path
            fill={fill}
            fillOpacity={fillOpacity}
            // transforms are applied right to left (matrix multiplication!)
            transform={`translate(${cx ?? 0}, ${cy ?? 0}) scale(${scaleFactor}, ${scaleFactor}) translate(${-(78 / 2)}, ${-19.5})`}
            d="M78 20C55.25 20 52 39 39 39C26.0001 39 22.75 20 0 20C-1.94926e-05 19.22 0 19.78 0 19C22.75 19 26.0001 0 39 0C52 0 55.25 19 78 19C78 19.78 78 19.22 78 20Z"
        />
    );
};

const trackProcessPulseHover = (): { startTracking: () => void; cancelTracking: () => void } => {
    let timeoutId: NodeJS.Timeout;
    const startTracking = () => {
        timeoutId = setTimeout(() => trackEvent({ category: "Grid", action: "Process Pulse Hovered" }), 1500);
    };
    const cancelTracking = () => clearTimeout(timeoutId);
    return { startTracking, cancelTracking };
};

/**
 * Renders a process pulse chart completely agnostic from where it is rendered (e.g. no references to table cells)
 *
 * @param {IProcessPulseProps} { width, processId, height = 39 }
 * @returns
 */
const ProcessPulse = ({ width, processId, height = 39 }: IProcessPulseProps) => {
    const numberOfWeeks = 12;

    const { t: translate } = useTranslation();
    const { ref, inView } = useInView({ triggerOnce: true });

    const pulseData = useProcessPulse(processId, inView);

    const theme = useTheme();

    // scale function contains internal state (domain & range), so each cell needs its own (memoized) instance
    const scaleToShapeHeight = useMemo(makeActivityScale, []);

    const scatterData = useMemo(() => {
        if (!pulseData.isSuccess) {
            return [];
        }
        const dataToUse = pulseData.data?.items;
        return dataToUse?.slice(0, numberOfWeeks);
    }, [pulseData.isSuccess, pulseData.data?.items]);

    // Add 0.5 at start & end so start/end values are properly visible and not at the edges
    const lastWeek = 12 - 1; // weeks are 0-indexed
    const xAxisDomain = useMemo(() => [-0.5, lastWeek + 0.5], [lastWeek]);
    const yAxisDomain = useMemo(() => [-height, height], [height]);

    // The raw activity values will be scaled to this interval
    const shapeHeightRange = useMemo(() => [0, height], [height]);

    const cartesianGridPoints = useMemo(
        () => ({
            horizontalPoints: [height / 2],
            verticalPoints: [width / 3, (width * 2) / 3],
        }),
        [height, width],
    );

    if (!pulseData.data && scatterData !== undefined) {
        return (
            <Root ref={ref}>
                <PulseSkeleton width={width - stripPxSuffix(theme.spacing(1))} height={height} />
            </Root>
        );
    }

    const { startTracking, cancelTracking } = trackProcessPulseHover();
    return scatterData !== undefined ? (
        <Root ref={ref}>
            <ScatterChart
                width={width}
                height={height}
                margin={NO_MARGINS}
                defaultShowTooltip
                onMouseEnter={startTracking}
                onMouseLeave={cancelTracking}
            >
                <CartesianGrid {...cartesianGridPoints} stroke={theme.palette.divider} height={height / 2} y={height / 4} />
                <XAxis reversed type="number" dataKey="week" domain={xAxisDomain} hide={true} />
                <YAxis dataKey={returnZero} domain={yAxisDomain} hide={true} />
                <ZAxis
                    zAxisId={DataSeries.ActivityChanges}
                    dataKey="activityChanges"
                    type="number"
                    range={shapeHeightRange}
                    scale={scaleToShapeHeight} // scale will map the raw activity values into shapeHeight values of the range defined above
                />
                <ZAxis
                    zAxisId={DataSeries.ProcessChanges}
                    dataKey="processChanges"
                    type="number"
                    range={shapeHeightRange}
                    scale={scaleToShapeHeight} // scale will map the raw activity values into shapeHeight values of the range defined above
                />
                <Scatter
                    zAxisId={DataSeries.ProcessChanges}
                    data={scatterData}
                    shape={PulseBubble}
                    isAnimationActive={false}
                    fill={theme.palette.magicPurple.main}
                    fillOpacity={0.5}
                />
                <Scatter
                    zAxisId={DataSeries.ActivityChanges}
                    data={scatterData}
                    shape={PulseBubble}
                    isAnimationActive={false}
                    fill={theme.palette.primary.main}
                    fillOpacity={0.5}
                />
                <Tooltip
                    content={<TooltipProcessPulse chartHeight={height} />}
                    cursor={{
                        fill: theme.palette.primary.light,
                        y: theme.spacing(-10),
                        strokeWidth: theme.spacing(2),
                        opacity: 0.5,
                    }}
                    // Disable animation because it conflicts with custom tooltip positioning by popper
                    isAnimationActive={false}
                    allowEscapeViewBox={{ y: true, x: true }} // do not apply any restrictions to keep tooltip element inside of the chart
                    offset={0} // place tooltip reference element exactly at the center of the hovered bubble
                ></Tooltip>
            </ScatterChart>
        </Root>
    ) : (
        <ErrorRoot sx={{ height }}>
            <Typography>{translate("processPulseError")}</Typography>
        </ErrorRoot>
    );
};

// Having two dedicated components makes it possible to effectively memoize the rendered pulse
// The table passes in lots of frequently changing data as props to their cells, so memoization does not work well
const MemoizedProcessPulse = React.memo(ProcessPulse);

/**
 * Extract the necessary information from the table data and forward it to a (memoized) process pulse chart component.
 *
 * @param {ITableProcessPulseCellProps} { column, row, height }
 */
const TableProcessPulseCell = ({ column, row, height }: ITableProcessPulseCellProps) => (
    <MemoizedProcessPulse height={height} width={column.totalWidth} processId={row.original.id} />
);
export default TableProcessPulseCell;
