import { Grid, styled } from "@mui/material";
import { FilteredMeasureDto } from "api-shared";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import MeasureIdChip from "../MeasureIdChip";
import UnseenChangesIcon from "../icons/UnseenChangesIcon";

const Helper = styled("div")(({ theme }) => ({
    width: theme.spacing(),
}));

function TableMeasureIdCell({ row, value }: CellProps<FilteredMeasureDto>) {
    const { t: translate } = useTranslation();
    const { unseenChanges } = row.original.calculatedFields;

    return (
        <Grid container justifyContent="center" spacing={1} wrap="nowrap">
            {unseenChanges && (
                <Grid item>
                    <UnseenChangesIcon title={translate("new_indicator_desk_hint")} />
                </Grid>
            )}
            <Grid item>
                <MeasureIdChip measureId={value} />
            </Grid>
            {unseenChanges && (
                // This is an invisible element that has the same width as the icon for unseen changes
                // Because the items in this container are centered, this causes the ID chips to always be aligned
                <Grid item>
                    <Helper />
                </Grid>
            )}
        </Grid>
    );
}

export default TableMeasureIdCell;
