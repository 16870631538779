import { Box, Divider, Stack, Typography, styled } from "@mui/material";
import { FilteredMeasureDto, MeasureConfigDto } from "api-shared";
import { TFunction } from "i18next";
import { isNumber } from "lodash";
import React from "react";
import MarkdownMentions from "../../components/MarkdownMentions";
import { MeasureBreadcrumb } from "../../components/MeasureBreadcrumb";
import ShowMore from "../../components/ShowMore";
import UserEntryWithPopup from "../../components/user/UserEntryWithPopup";
import { useMeasureAssignedTo } from "../../domain/measure/detail";
import useCurrency from "../../hooks/useCurrency";
import { translationKeys } from "../../translations/main-translations";
import PreviewEffectCategory from "./PreviewEffectCategory";
import PreviewPartialEffect from "./PreviewPartialEffect";

const SpacedDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(2, 0),
}));

const GridLayout = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr auto",
    columnGap: theme.spacing(1),
}));

interface IProcessPreviewDialogProps {
    measure: FilteredMeasureDto;
    measureConfig: MeasureConfigDto;
    translate: TFunction;
    isFeedPreview?: boolean;
}

const ProcessPreviewDialogContent = ({ measure, measureConfig, translate, isFeedPreview }: IProcessPreviewDialogProps) => {
    const descriptionRef = React.useRef<HTMLDivElement>(null);

    const {
        clientIid,
        title,
        fields: { description },
        calculatedFields: { effectNoScope, effect },
    } = measure;

    const assignedToUser = useMeasureAssignedTo(measure);

    const { formatCurrency } = useCurrency();

    const processName = measureConfig != null ? translate(measureConfig.name) : "";

    return (
        <div>
            <Stack direction="row" spacing={1} alignItems="flex-start">
                <Box flexGrow={1}>
                    <MeasureBreadcrumb label={processName} measureDisplayId={clientIid} />
                    <Typography variant="h6" component="div" mb={2}>
                        {title}
                    </Typography>
                </Box>
                {assignedToUser != null ? <UserEntryWithPopup avatarProps={{ size: 32 }} iconOnly user={assignedToUser} /> : null}
            </Stack>
            <Typography variant="subtitle2" mb={0.5}>{`${translate("description")}:`}</Typography>
            {typeof description === "string" && description.length > 0 ? (
                <Box mb={1}>
                    <ShowMore height={240} translate={translate} contentRef={descriptionRef}>
                        <MarkdownMentions markdown={description} ref={descriptionRef} />
                    </ShowMore>
                </Box>
            ) : (
                <Typography color="textSecondary">{translate(translationKeys.VDLANG_MEASURE_PREVIEW_NO_DESCRIPTION)}</Typography>
            )}
            <SpacedDivider />
            {measure.effectCategories.length > 0 ? (
                <GridLayout>
                    {measure.effectCategories.map((ec, index) => (
                        <PreviewEffectCategory
                            key={ec.id}
                            translate={translate}
                            effectCategory={ec}
                            effectCategoryAttributes={measureConfig.effectCategoryAttributes}
                            isFeedPreview={isFeedPreview}
                        />
                    ))}
                </GridLayout>
            ) : (
                <Typography color="textSecondary">{translate(translationKeys.VDLANG_MEASURE_PREVIEW_NO_EFFECT_CATEGORIES)}</Typography>
            )}
            <SpacedDivider />
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle2">{translate(translationKeys.VDLANG_MEASURE_PREVIEW_TOTAL_EFFECT)}</Typography>
                <Typography color="textSecondary" variant="subtitle2">
                    <PreviewPartialEffect
                        formatCurrency={formatCurrency}
                        partial={isNumber(effect) ? effect : undefined}
                        total={isNumber(effectNoScope) ? effectNoScope : null}
                    />
                </Typography>
            </Stack>
        </div>
    );
};

export default ProcessPreviewDialogContent;
